// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

//@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Sharp:opsz,wght,FILL,GRAD@48,600,0,0&family=EB+Garamond:ital@1&family=Nunito+Sans:ital,wght@0,300;0,600;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;1,400;1,500&family=Nunito+Sans:ital,wght@0,300;0,600;1,300&display=swap');

@font-face {
  font-display: swap;
  font-family: 'Material Symbols Sharp';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fontsLight.woff2') format('woff2');
}

$typography: mat.define-typography-config($font-family: 'Nunito Sans',
$headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.define-typography-level(34px, 40px, 400, 'Nunito Sans', normal),
$headline-5: mat.define-typography-level(45px, 48px, 400, 'Nunito Sans', normal), // H1
$headline-6: mat.define-typography-level(23px, 25px, 500, 'EB Garamond', normal), // h2
$subtitle-1: mat.define-typography-level(24px, 31.2px, 600, 'Nunito Sans', normal), // h3
$subtitle-2: mat.define-typography-level(18px, 21px, 400, 'Nunito Sans', normal), // h4
$body-1: mat.define-typography-level(18px, 24px, 400, 'Nunito Sans', normal), // Bold P
$body-2: mat.define-typography-level(13px, 19px, 400, 'Nunito Sans', normal), // P
$caption: mat.define-typography-level(14px, 18px, 400, 'Nunito Sans', normal), // Caption
$button: mat.define-typography-level(14px, 22px, 400, 'Nunito Sans', normal));

/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50 : #e5e6e7,
  100 : #bec2c3,
  200 : #93999c,
  300 : #677074,
  400 : #475156,
  500 : #000,
  600 : #222d32,
  700 : #1c262b,
  800 : #171f24,
  900 : #0d1317,
  A100 : #5bb9ff,
  A200 : #28a3ff,
  A400 : #008bf4,
  A700 : #007dda,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #fff,
    A700 : #fff,
  )
);

$md-secondary: (
  50 : #f7f7f7,
  100 : #ebebeb,
  200 : #dedede,
  300 : #d1d1d1,
  400 : #c7c7c7,
  500 : #bdbdbd,
  600 : #b7b7b7,
  700 : #aeaeae,
  800 : #a6a6a6,
  900 : #989898,
  A100 : #fff,
  A200 : #fff,
  A400 : #ffd4d4,
  A700 : #fbb,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-accent: (
  50 : #eaf5ea,
  100 : #c9e7cb,
  200 : #a6d7a8,
  300 : #82c785,
  400 : #67bb6a,
  500 : #4caf50,
  600 : #45a849,
  700 : #3c9f40,
  800 : #339637,
  900 : #248627,
  A100 : #c5ffc7,
  A200 : #92ff95,
  A400 : #5fff64,
  A700 : #46ff4b,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$md-warn: (
  50 : #ffefef,
  100 : #ffd6d6,
  200 : #fbb,
  300 : #ff9f9f,
  400 : #ff8b8b,
  500 : #ff7676,
  600 : #ff6e6e,
  700 : #ff6363,
  800 : #ff5959,
  900 : #ff4646,
  A100 : #fff,
  A200 : #fff,
  A400 : #ffeded,
  A700 : #ffd4d4,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$jhc-fe-primary: mat.define-palette($md-primary);
$jhc-fe-accent: mat.define-palette($md-accent, A200, A100, A400);
$jhc-fe-warn: mat.define-palette($md-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$jhc-fe-theme: mat.define-light-theme((
  color: (
    primary: $jhc-fe-primary,
    accent: $jhc-fe-accent,
    warn: $jhc-fe-warn,
  ),
  typography: $typography,
));
@include mat.core-theme($jhc-fe-theme);
@include mat.all-component-typographies($typography);

@include mat.core();

// The following mixins include base theme styles that are only needed once per application. These
// theme styles do not depend on the color, typography, or density settings in your theme. However,
// these styles may differ depending on the theme's design system. Currently all themes use the
// Material 2 design system, but in the future it may be possible to create theme based on other
// design systems, such as Material 3.
//
// Please note: you do not need to include the 'base' mixins, if you include the corresponding
// 'theme' mixin elsewhere in your Sass. The full 'theme' mixins already include the base styles.
//
// To learn more about "base" theme styles visit our theming guide:
// https://material.angular.io/guide/theming#theming-dimensions
//
// TODO(v17): Please move these @include statements to the preferred place in your Sass, and pass
// your theme to them. This will ensure the correct values for your app are included.
//@include mat.all-component-bases(/* TODO(v17): pass $your-theme here */);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($jhc-fe-theme);
// Emit styles for MatButton based on `$my-theme`. Because the configuration
// passed to `define-light-theme` omits typography, `button-theme` will not
// emit any typography styles.
@include mat.button-theme($jhc-fe-theme);
@include mat.checkbox-theme($jhc-fe-theme);
@include mat.menu-theme($jhc-fe-theme);
@include mat.radio-theme($jhc-fe-theme);
@include mat.chips-theme($jhc-fe-theme);
@include mat.autocomplete-theme($jhc-fe-theme);

.material-symbols-sharp {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Symbols Sharp', serif;
  font-feature-settings: 'liga';
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variation-settings: 'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
}

// General JHC overrides
.jhc-filler {
  flex-grow: 1;
}

.jhc-actions {
  display: flex;
  justify-content: center;
  margin: 10px auto;
}

button {
  border-radius: 0 !important;
  text-transform: uppercase;
}

// Make all headings align text center
h1,
h2,
h3,
h4,
h5 {
  text-align: center;
}

h2 {
  font-style: italic !important;
  font-weight: 500;
  line-height: 30px !important;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  width: 100%;
}

a {
  color: #000;
}

* {
  box-sizing: border-box;
}

// Remove the background color applied on autocomplete in inputs
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #fff inset;
}

.panel {
  @media only screen and (max-width: 600px) {
    min-width: 97vw;
  }
}
